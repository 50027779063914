import React from 'react';
import { ContentPanel } from './index';

const ThanksPage = ({ siteTitle }) => {
  document.title = `Thank You | ${ siteTitle }`;
  return <ContentPanel title="Thank you for your interest!" useDecorations={false} contactLink={false}>
    <p>We will be responding in the next 2-3 business days.</p>
  </ContentPanel>
}

export default ThanksPage;
