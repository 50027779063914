import React from 'react';
import { Link } from 'react-router-dom';

// used to construct the web page
const ContentPanel = ({title, useDecorations = true, contactLink = true, children}) => {
  return (
    <div id="content-panel">
      <h2 id="svc-title" className={useDecorations ? "title-deco" : null}>{title}</h2>
      <div id="page-content" className={useDecorations ? "content-deco" : null}>
        {
          children
        }
        {contactLink ? <Link to="/contact" style={{color: '#222222',textDecoration: 'underline', fontSize: '2em', fontWeight: 'bold'}}>-&gt; Contact Us &lt;-</Link> : null}
      </div>
    </div>
  )
}

export default ContentPanel;
