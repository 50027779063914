import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import { ContentPanel } from './index';

const ContactPage = ({siteTitle}) => {
  document.title = `Contact | ${ siteTitle }`;

  /*const history = useHistory();

  const [formName, setFormName] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formNumber, setFormNumber] = useState('');
  const [formInterest, setFormInterest] = useState('gfx');
  const [formMessage, setFormMessage] = useState('');
  const [formError, setFormError] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const nextFormError = [];

    if (!formName) nextFormError.push("Please enter a name");
    if (!formEmail && !formNumber) nextFormError.push("Please enter an e-mail or phone number");
    if (!formMessage) nextFormError.push("Please enter a message explaining what you're looking for");

    if (nextFormError.length > 0) {
      setFormError(nextFormError);
      return;
    }

    setFormError([]);

    const sentData = {
      name: formName,
      email: formEmail,
      number: formNumber,
      interest: formInterest,
      message: formMessage
    }

    const rsp = await fetch('http://localhost:2222/api/messages', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(sentData)
    })

    const data = await rsp.json();

    console.log(data);

    history.push('/thanks');
  }*/

  return <ContentPanel title="Contact Us" useDecorations={false} contactLink={false}>
    <p>
      please use one of the methods below to get in touch <br />
      <sub>send an e-mail to ramirez.amadeo1@gmail.com</sub><br />
      <sub>or call/text (805)256-8319</sub>
    </p>

    {/*{formError.length > 0 ? (
      <div className="form-error-container">
        {
          formError.map((err, idx) => (
            <span key={idx} className="form-error">{err}</span>
          ))
        }
      </div>
    ) : null}

    <form id="contact-form" onSubmit={handleSubmit} >
      <div>
        <label htmlFor="form-name">Name: </label>
        <input id="form-name" type="text" maxlen="32" value={formName} onChange={
          (e) => setFormName(e.target.value)
        }/>
      </div>

      <div>
        <label htmlFor="form-number">Contact #: </label>
        <input id="form-number" type="text" maxlen="16" value={formNumber} onChange={
          (e) => setFormNumber(e.target.value)
        }/>
      </div>

      <div>
        <label htmlFor="form-email">E-Mail: </label>
        <input id="form-email" type="text" maxlen="64" value={formEmail} onChange={
          (e) => setFormEmail(e.target.value)
        }/>
      </div>

      <label htmlFor="form-interest">Service: </label>
      <select id="form-interest" value={formInterest} onChange={
        e => setFormInterest(e.target.value)
      }>
        <option value="gfx">Graphic Design</option>
        <option value="vfx">Multimedia Production</option>
        <option value="web">Web Development</option>
      </select>
      <br />

      <label htmlFor="form-message">Message: </label>
      <br />
      <textarea id="form-message" value={formMessage} style={{width: "100%", resize: "none"}} rows="10" onChange={
        (e) => setFormMessage(e.target.value)
      }></textarea>
      <br />

      <button type="submit">Send</button>

    </form>*/}
  </ContentPanel>
}

export default ContactPage;
