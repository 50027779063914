// import react modules
import {
    React,
    useState,
    useEffect
} from 'react';
// import reactDOM **important for rendering content
import ReactDOM from 'react-dom';

// import for many different hyperlink services
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Link,
    useLocation
} from 'react-router-dom';

// import my stuff
import {
  NotFound,
  ContentPanel,
  HomePage,
  WebService,
  GfxService,
  VfxService,
  NavMenu,
  ContactPage,
  ThanksPage
} from './components'

//get the root element that will 'house' the 'app'
const root = document.getElementById("root");

const App = () => {
  const location = useLocation();
  const siteTitle = window.location.hostname === "services.622.studio" ? "622 Multi-Resolutions" : "Einstein Binary";
  document.body.style.backgroundImage = window.location.hostname === "services.622.studio" ? "url('images/background-default.png')" : "url('images/background-einstein.png')";

  const skills = {
    "Adobe Creative Cloud Suite": [
      "After Effects",
      "Animate",
      "Dreamweaver",
      "Illustrator",
      "Photoshop",
      "Premiere",
    ],
    "Frameworks": [
      "jQuery",
      "React",
      "Express",
      "PostgreSQL",
      "Node"
    ],
    "Languages": [
      "Python",
      "HTML",
      "JavaScript",
      "CSS",
      "C#",
      "C++",
      "PHP",
      "Swift"
    ],
    "Other Technologies": [
      "Arduino",
      "Raspberry Pi",
      "Git",
      "Heroku",
      "cPanel",
      "WordPress"
    ],
    "Studio Production": [
      "Audio Technician",
      "Camera Operator",
      "Director",
      "Lighting Technician",
      "Production Assistant",
      "Switcher Operator"
    ],
    "Miscellaneous": [
      "DVD Authoring",
      "Final Cut Pro",
      "Microsoft Word",
      "Microsoft PowerPoint"
    ]
  }

  const [qjActive, setQJActive] = useState(false);

  useEffect(() => {
    if (qjActive) setQJActive(false);
  }, [location]);

    return (
        <div id="main-panel">
          <header id="page-header">
            <Link to="/" id="site-title">{siteTitle}</Link>
            {
              location.pathname != '/' ? <NavMenu qjActive={qjActive} setQJActive={setQJActive} /> : null
            }
          </header>

            <Switch>
                <Route path="/multimedia">
                  <VfxService skills={skills} skillset={["Adobe Creative Cloud Suite", "Studio Production", "Miscellaneous"]} siteTitle={siteTitle} />
                </Route>

                <Route path="/graphics">
                  <GfxService siteTitle={siteTitle} />
                </Route>

                <Route path="/web">
                  <WebService skills={skills} skillset={["Frameworks", "Languages", "Other Technologies"]} siteTitle={siteTitle} />
                </Route>

                <Route path='/contact'>
                  <ContactPage siteTitle={siteTitle} />
                </Route>

                <Route path='/thanks'>
                  <ThanksPage siteTitle={siteTitle} />
                </Route>

                <Route exact path="/">
                  <HomePage siteTitle={siteTitle} />
                </Route>

                <Route path="*">
                  <NotFound  siteTitle={ siteTitle } />
                </Route>
            </Switch>
        </div>
    );
};

ReactDOM.render(<Router><App /></Router>,root);
