import React, {useState} from 'react';
import {Link} from 'react-router-dom';

const NavMenu = ({qjActive, setQJActive}) => {

  return <>
    <button onClick={() => {setQJActive(!qjActive);}}>Quick Jump</button>
    {
      qjActive ? <div id="qj-panel">
        <Link to="graphics">Graphics</Link>
        <Link to="multimedia">Multimedia</Link>
        <Link to="web">Web Development</Link>
      </div> : null
    }
  </>
}

export default NavMenu;
