import { React } from 'react';
import { SkillsContainer } from './index';

import { ContentPanel, ProjectCard } from './index';

const WebService = ({ skills, skillset, siteTitle }) => {
  const nextSkills = {};
  skillset.forEach((skillName) => nextSkills[skillName] = skills[skillName]);

  document.title = `Web Services | ${ siteTitle }`;

  return <ContentPanel title="Web Development">
    <h1>From Zero to API Hero</h1>
    <p style={{marginTop: '0'}}>
      Amadeo is a recent graduate from Fullstack Academy, a tech bootcamp based in New York.
      There, he learned the most efficient methods of building a website, from the backend to the front end.
    </p>
    <img src='images/web-dev.png' />
    <p>
      Amadeo is now proudly a part of Fullstack Academy staff, serving as an Instructor Associate.
      He has also done work in the past as a self-taught web developer for local dispensaries and start-ups.
    </p>

    <h1>Web Development Skills</h1>
    <SkillsContainer skillCategories={nextSkills} />

    <p>Some projects completed during the Fullstack Course:</p>

    <ProjectCard name="Snake Game" description={
      `Classic snake game recreated by Amadeo.`
    } imageURL="images/snake-game.png" link="https://festive-kare-73eecb.netlify.app/" linkName="-&gt; Live Demo &lt;-" />
    <ProjectCard name="Stranger's Things" description={
      `Craigslist clone, complete with user accounts and messages.`
    } imageURL="images/strangers-things.png" link="https://laughing-murdock-7479cc.netlify.app/" linkName="-&gt; Live Demo &lt;-" />
    <ProjectCard name="Food with Friends" description={
      `Mockup of an online store dedicated to cooking with fine alcohol.`
    } imageURL="images/food-with-friends.png" link="http://code-warriors.herokuapp.com/" linkName="-&gt; Live Demo &lt;-" />
  </ContentPanel>
}

export default WebService;
