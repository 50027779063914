// import react modules
import {
    React
} from 'react';
import {
  Link
} from 'react-router-dom';

const ServiceIcon = ({title,link}) => {
  return (
    <Link to={link}>
      <div className="service-icon">
        <img src={`/images/service-icons${link}-icon.png`}/><br></br>
        {title}
      </div>
    </Link>
  )
}

export default ServiceIcon;
