import {React, useState} from 'react';

const SkillsContainer = ({skillCategories}) => {
  return <div className="skills-container">
    {
      Object.keys(skillCategories).map((skillCategory, idx) => {
        const [isOpen, setIsOpen] = useState(false);
        return <div key={idx} className="skill-category" onClick={() => {
          setIsOpen(!isOpen);
        }
        }>
          <p>{skillCategory}</p>
          <img className={isOpen ? "open" : null} src="./images/widget/arrow.png" />
          {
            isOpen ? <ul>
              {skillCategories[skillCategory].map((skillName, idx) => {
                return <li key={idx}>
                  {skillName}
                </li>
              })}
            </ul> : null
          }
        </div>
      })
    }
  </div>
}

export default SkillsContainer;
