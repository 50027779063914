import { React } from 'react';
import { ContentPanel } from './index';

const GfxService = ({ siteTitle }) => {
  document.title = `Graphics Services | ${ siteTitle }`;
  return <ContentPanel title="Graphic Design">
    <h1>UNDER CONSTRUCTION</h1>
  </ContentPanel>
}

export default GfxService;
