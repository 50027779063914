import {React, useState} from 'react';
import {ContentPanel, SkillsContainer, ProjectCard} from './index';

const VfxService = ({skills, skillset, siteTitle}) => {
  const nextSkills = {};
  skillset.forEach((skillName) => nextSkills[skillName] = skills[skillName]);
  document.title = `Multimedia Services | ${ siteTitle }`;

  return <ContentPanel title="Multimedia Production">
    <h1>Got a story to tell?</h1>
    <img src="images/weekend.png" />
    <p style={{marginTop: 0}}>
      Our production studio will work with you to bring your vision to life!
    </p>

    <h1>Multimedia and Production Skills</h1>
    <SkillsContainer skillCategories={nextSkills} />

    <p>
      We've worked on various small projects and some for public access television, including:
    </p>

    <ProjectCard name="GET MOVING TV" count="4" type="episodes" roles={["Audio Tech", "Camera Operator", "Editor"]} description={`
      Ventura pediatrician Dr. Chris Landon hosts a talk show that delves into various interesting topics, not always constrained to the medical field.
    `} imageURL={"/images/project-cards/gmtv.png"}/>

    <ProjectCard name="SCHMOOZE with SUZ" count="5" type="episodes" roles={["Camera Operator", "Editor", "Lighting Tech", "Production Assistant"]} description={`
      Extended Learning Academy founder Suz Montgomery reached out to many members of the community to keep Ventura County informed on local news and events.
    `} imageURL={"/images/project-cards/schmz.png"}/>

    <ProjectCard name="PIZZA CHEF COMMERCIAL" type="short" roles={["Camera Operator", "Editor", "Production Assistant"]} description={`
      A girl sells her soul for a slice, but was it worth it?
    `} imageURL={"/images/project-cards/pzchf.png"}/>

    <ProjectCard name="ZOLTAN'S GARDEN" count="2" type="episodes" roles={["Director", "Editor", "Producer"]} description={`
      The Home and Family Channel presents a look into a garden that is gaining popularity in the community.
    `} imageURL={"/images/project-cards/zgdn.png"}/>

  </ContentPanel>
}

export default VfxService;
