import React from 'react';
import { ContentPanel } from './index';

const NotFound = ({siteTitle}) => {
  document.title = `404 | ${ siteTitle }`;
  return (
    <ContentPanel title="PAGE DOES NOT EXIST" useDecorations={false} contactLink={false} />
  )
}

export default NotFound;
