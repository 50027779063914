const ProjectCard = ({name, count, type, roles, description, imageURL, link, linkName}) => {
  return <div className="project-card">
    <p className="text-emp" style={{marginBottom: 0}}>
      {name}
      <br />
      {type ? <sub>( {count ? `${count} ${type}` : type} )</sub> : null}
      {link ? <sub> <a target="_blank" href={link}>{linkName}</a> </sub> : null}
    </p>
    {roles ? <p style={{marginTop: '0.5em', marginBottom: '0.5em', fontSize: '1.3em'}}>{roles.join(', ')}</p> : null}

    <img src={imageURL} />
    <p style={{marginTop: 0}}>
      {description}
    </p>
  </div>
}

export default ProjectCard;
