// import react modules
import {
    React,
    useEffect
} from 'react';

import {
  Link
} from 'react-router-dom';

// import my stuff
import {
  ContentPanel,
  ServiceIcon
} from './index'

const HomePage = ({siteTitle}) => {
  document.title = `Services | ${ siteTitle }`;


  return (<ContentPanel title="Welcome" useDecorations={false}>

    <p>
      {siteTitle} is a team of highly skilled visionaries, ready to work with you and your next big endeavor!
    </p>

    <p className="text-emp">the services we offer</p>

    <div id="service-grid">
      <ServiceIcon title="Graphic Design" link="/graphics"/>
      <ServiceIcon title="Multimedia Production" link="/multimedia"/>
      <ServiceIcon title="Web Development" link="/web"/>
    </div>

    <hr />
    <h2>Our Team</h2>
    <h3>Based in Southern California</h3>
    <img src="images/sc.png" />
    <p>
      We are experimental in our approach,
      working based on the philosophy that no individual person could possibly know everything about a particular subject.
      We believe this way of thinking encourages more active listening and open environments for discussion.
    </p>

    <p>
      Whether you're already established or starting from the ground up, {siteTitle} carries with it the professionalism and tools required to get the job done.
    </p>

    <p>
      <i>
        You couldn't have chosen a better square one to start from!!
      </i>
    </p>
  </ContentPanel>)
}

export default HomePage;
